import { CHAIN_ID } from 'src/config/chain.d'

export const APP_ENV = process.env.REACT_APP_ENV
export const NODE_ENV = process.env.NODE_ENV
export const IS_PRODUCTION = APP_ENV === 'production'
export const IS_DEV = APP_ENV === 'dev'
export const IS_ROBIN = APP_ENV === 'robin'
// For debugging on dev
const isProdGateway = () => {
  try {
    return localStorage.getItem(`${LS_NAMESPACE}${LS_SEPARATOR}${LS_USE_PROD_CGW}`) === 'true'
  } catch (e) {
    return false
  }
}

// default chainId
let defaultChainId
if (IS_PRODUCTION || isProdGateway()) {
  defaultChainId = CHAIN_ID.RANGERS
} else if (IS_DEV) {
  defaultChainId = CHAIN_ID.RANGERS_DEV
} else if (IS_ROBIN) {
  defaultChainId = CHAIN_ID.RANGERS_ROBIN
}

export const DEFAULT_CHAIN_ID = defaultChainId
export const DEFAULT_CONTRACT_ADDRESS = '0xb4A7C7da1631CF60A2Cf23ABc86986f99a1A7f70'
export const PUBLIC_URL = process.env.PUBLIC_URL
export const TX_SERVICE_VERSION = '1'
export const INTERCOM_ID = IS_PRODUCTION ? process.env.REACT_APP_INTERCOM_ID : 'plssl1fl'
export const BEAMER_ID = IS_PRODUCTION ? process.env.REACT_APP_BEAMER_ID : 'ehlRMhQi41258'
export const GOOGLE_ANALYTICS_ID = process.env.REACT_APP_GOOGLE_ANALYTICS || ''
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN || ''
export const PORTIS_ID = process.env.REACT_APP_PORTIS_ID ?? '852b763d-f28b-4463-80cb-846d7ec5806b'
export const FORTMATIC_KEY = process.env.REACT_APP_FORTMATIC_KEY ?? 'pk_test_CAD437AA29BE0A40'
export const INFURA_TOKEN = process.env.REACT_APP_INFURA_TOKEN || ''
export const SAFE_APPS_RPC_TOKEN = process.env.REACT_APP_SAFE_APPS_RPC_INFURA_TOKEN || ''
export const LATEST_SAFE_VERSION = process.env.REACT_APP_LATEST_SAFE_VERSION || '1.3.0'
export const APP_VERSION = process.env.REACT_APP_APP_VERSION || 'not-defined'
export const COLLECTIBLES_SOURCE = process.env.REACT_APP_COLLECTIBLES_SOURCE || 'Gnosis'
export const SAFE_POLLING_INTERVAL = process.env.NODE_ENV === 'test' ? 4500 : 15000
export const ETHERSCAN_API_KEY = process.env.REACT_APP_ETHERSCAN_API_KEY || ''
export const ETHGASSTATION_API_KEY = process.env.REACT_APP_ETHGASSTATION_API_KEY
export const IPFS_GATEWAY = process.env.REACT_APP_IPFS_GATEWAY
export const SPENDING_LIMIT_MODULE_ADDRESS =
  process.env.REACT_APP_SPENDING_LIMIT_MODULE_ADDRESS || '0xCFbFaC74C26F8647cBDb8c5caf80BB5b32E43134'

export const WC_BRIDGE = 'https://safe-walletconnect.gnosis.io/'

// Google Tag Manager
export const GOOGLE_TAG_MANAGER_ID = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID || ''
export const GOOGLE_TAG_MANAGER_AUTH_LIVE = process.env.REACT_APP_GOOGLE_TAG_MANAGER_LIVE_AUTH || ''
export const GOOGLE_TAG_MANAGER_AUTH_LATEST = process.env.REACT_APP_GOOGLE_TAG_MANAGER_LATEST_AUTH || ''
export const GOOGLE_TAG_MANAGER_DEVELOPMENT_AUTH = process.env.REACT_APP_GOOGLE_TAG_MANAGER_DEVELOPMENT_AUTH || ''

// localStorage-related constants
export const LS_NAMESPACE = 'SAFE'
export const LS_SEPARATOR = '__'
export const LS_USE_PROD_CGW = 'useProdGateway'

console.log('APP_ENV')
console.log(APP_ENV)

// GATEWAY_URL
const DEV_GATEWAY_URL = process.env.REACT_APP_GATEWAY_URL_DEV
const ROBIN_GATEWAY_URL = process.env.REACT_APP_GATEWAY_URL_ROBIN
const PRODUCTION_GATEWAY_URL = process.env.REACT_APP_GATEWAY_URL
let gateWayUrl
if (IS_PRODUCTION || isProdGateway()) {
  gateWayUrl = PRODUCTION_GATEWAY_URL
} else if (IS_DEV) {
  gateWayUrl = DEV_GATEWAY_URL
} else if (IS_ROBIN) {
  gateWayUrl = ROBIN_GATEWAY_URL
}
export const GATEWAY_URL = gateWayUrl
